export default {
  "id": "fb0af4e0a8846e6adbd28e971f883bd6",
  "tags": {},
  "filePath": "/home/runner/work/react-showroom/react-showroom/docs/docs/components/button.tsx",
  "description": "",
  "displayName": "Button",
  "methods": [],
  "props": {
    "variant": {
      "defaultValue": null,
      "description": "",
      "name": "variant",
      "parent": {
        "fileName": "docs/docs/components/button.tsx",
        "name": "ButtonProps"
      },
      "declarations": [
        {
          "fileName": "docs/docs/components/button.tsx",
          "name": "ButtonProps"
        }
      ],
      "required": true,
      "type": {
        "name": "enum",
        "raw": "\"primary\" | \"secondary\"",
        "value": [
          {
            "value": "\"primary\""
          },
          {
            "value": "\"secondary\""
          }
        ]
      },
      "tags": {}
    },
    "fullWidth": {
      "defaultValue": null,
      "description": "",
      "name": "fullWidth",
      "parent": {
        "fileName": "docs/docs/components/button.tsx",
        "name": "ButtonProps"
      },
      "declarations": [
        {
          "fileName": "docs/docs/components/button.tsx",
          "name": "ButtonProps"
        }
      ],
      "required": false,
      "type": {
        "name": "enum",
        "raw": "boolean",
        "value": [
          {
            "value": "false"
          },
          {
            "value": "true"
          }
        ]
      },
      "tags": {}
    },
    "rounded": {
      "defaultValue": null,
      "description": "",
      "name": "rounded",
      "parent": {
        "fileName": "docs/docs/components/button.tsx",
        "name": "ButtonProps"
      },
      "declarations": [
        {
          "fileName": "docs/docs/components/button.tsx",
          "name": "ButtonProps"
        }
      ],
      "required": false,
      "type": {
        "name": "enum",
        "raw": "\"sm\" | \"lg\"",
        "value": [
          {
            "value": "\"sm\""
          },
          {
            "value": "\"lg\""
          }
        ]
      },
      "tags": {}
    }
  }
}