export default {
  "id": "004be3f250be9f9730afdfa6c272712b",
  "tags": {
    "deprecated": "use `<Button />` instead.",
    "version": "1.0.0",
    "see": "http://some-stackoverflow-question-that-i-copy-but-dont-understand.com"
  },
  "filePath": "/home/runner/work/react-showroom/react-showroom/docs/docs/components/old-button.tsx",
  "description": "",
  "displayName": "OldButton",
  "methods": [],
  "props": {
    "color": {
      "defaultValue": null,
      "description": "",
      "name": "color",
      "parent": {
        "fileName": "docs/docs/components/old-button.tsx",
        "name": "OldButtonProps"
      },
      "declarations": [
        {
          "fileName": "docs/docs/components/old-button.tsx",
          "name": "OldButtonProps"
        }
      ],
      "required": true,
      "type": {
        "name": "enum",
        "raw": "\"primary\" | \"secondary\"",
        "value": [
          {
            "value": "\"primary\""
          },
          {
            "value": "\"secondary\""
          }
        ]
      },
      "tags": {}
    }
  }
}